<template>
    <!-- ref用于获取wrapper的dom元素 -->
    <div id="wrapper" ref="wrapper">
        <!-- 由于内部内容不确定，所以放一个插槽在这，谁要使用iscroll再添加进来 -->
        <slot></slot>
    </div>
</template>

<script>
import IScroll from '../assets/js/iscroll'
export default {
    name: 'ScrollView',    
    mounted(){
        this.iscroll = new IScroll(this.$refs.wrapper,{
            mouseWheel: true,
            scrollbars: true,
            scrollY: true,
            // scrollX: false,
            disablePointer: false,
            // disableTouch: false,
            // disableMouse: false
        })
    }
}
</script>

<style scoped>
#wrapper{
    width: 100%;
    height: 100%;
}
</style>