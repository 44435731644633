const qusedata = [{

	"id": 1,

	"type": "单选题",

	"title": "流动人口符合生育政策计划怀孕的夫妇，可在现居住地享受免费孕前优生健康检查，所需证件：夫妻双方有效身份证，结婚证，及现居住地开具证明到乐从医院三楼进行检查，请问乐从医院优生检查的时间设定为？",

	"options": ["每逢周一早上8：30", "每逢周三早上8：30", "每逢周五早上8：30", "每逢周六早上8：30"],

	"right": "C"

}, {

	"id": 2,

	"type": "多选题",

	"title": "以下什么情况下，流动人口可在现居住地申请办理生育登记申请？",

	"options": ["夫妻双方均为广东省外户籍地对象可以办理", "夫妻双方其中一方为广东省内户籍的对象可以办理", "夫妻双方均为广东省内户籍的对象可以办理"],

	"right": "BC"

}, {

	"id": 3,

	"type": "多选题",

	"title": "流动人口在现居住地办理生育登记需要提供什么资料？",

	"options": ["夫妻双方的身份证或户口簿", "女方的居住证", "结婚证", "女方怀孕证明"],

	"right": "ABCD"

}, {

	"id": 4,

	"type": "多选题",

	"title": "符合在当地享受孕前优生健康检查的对象为：（   ）。",

	"options": ["适龄拟结婚对象", "新婚夫妇", "准备生育二孩的夫妇", "已婚待孕夫妇"],

	"right": "ABCD"

}, {

	"id": 5,

	"type": "单选题",

	"title": "流动人口育龄妇女在什么情况下做长效节育手术可以在现居住地办理报销手续？",

	"options": ["所有已婚育龄妇女进行计划生育手术均可报销", "没有违反计划生育情况且现怀孕为政策内的，做长效节育手术可以办理报销手续", "没有违反计划生育情况并现怀孕为政策外的，做长效节育手术可以办理报销手续", "未婚怀孕的妇女进行计划生育手术，可以办理报销手续"],

	"right": "C"

}, {

	"id": 6,

	"type": "多选题",

	"title": "未违反计划生育的已婚育龄妇女，在当地可以享受哪几种长效避孕措施手术优惠？",

	"options": ["上环节", "结扎", "皮埋"],

	"right": "ABC"

}, {

	"id": 7,

	"type": "单选题",

	"title": "流动人口是否可以在本辖区领取免费避孕药具？",

	"options": ["可以", "不可以"],

	"right": "A"

}, {

	"id": 8,

	"type": "单选题",

	"title": "符合计划生育现孕的夫妇，是否可以在现居住地享受免费产检项目？",

	"options": ["可以", "不可以"],

	"right": "A"

}, {

	"id": 9,

	"type": "单选题",

	"title": "凡居住在本辖区的流动人口，是否可以在现居住地办理居民健康档案？",

	"options": ["可以", "不可以"],

	"right": "A"

}, {

	"id": 10,

	"type": "单选题",

	"title": "免费享受两癌复查检查的对象为：（   ）。",

	"options": ["35-64周岁常住已婚妇女", "25-64周岁常住已婚妇女", "20-64周岁常住已婚妇女", "18-64周岁常住已婚妇女"],

	"right": "A"

}, {

	"id": 11,

	"type": "单选题",

	"title": "胎儿尚未出生，父亲因车祸不幸身亡，胎儿是否有权继承父亲的遗产？",

	"options": ["有", "没有"],

	"right": "A"

}, {

	"id": 12,

	"type": "单选题",

	"title": "7岁的小朋友，将父亲送给他的一块手表卖给了二手商店，其父母能要求退回吗？",

	"options": ["能", "不能"],

	"right": "A"

}, {

	"id": 13,

	"type": "单选题",

	"title": "13岁的同学偶尔看到妈妈付款密码，用妈妈的手机看直播，一时冲动私自给主播打赏8万元，妈妈事后发现，能追回打赏吗？",

	"options": ["能", "不能"],

	"right": "A"

}, {

	"id": 14,

	"type": "单选题",

	"title": "子女遭父亲虐待，父亲被法院撤销监护权后，有义务继续付抚养费吗？",

	"options": ["有", "没有"],

	"right": "A"

}, {

	"id": 15,

	"type": "单选题",

	"title": "网购商品用快递送达，商品在快递途中、签收之前毁损的风险谁承担？",

	"options": ["卖家", "买家"],

	"right": "A"

}, {

	"id": 16,

	"type": "单选题",

	"title": "租房期间，房东把房子卖了，租客与原房东的租赁合同还有效吗？",

	"options": ["有效", "无效"],

	"right": "A"

}, {

	"id": 17,

	"type": "单选题",

	"title": "在餐厅吃饭时，明知服务员上错菜仍然吃完，是否应该付钱？",

	"options": ["应该", "不应该"],

	"right": "A"

}, {

	"id": 18,

	"type": "单选题",

	"title": "参加单位组织的足球比赛，却因和对方球员发生规则范围内的碰撞，导致脚踝骨折，可以向对方请求赔偿吗？",

	"options": ["不可以", "可以"],

	"right": "A"

}, {

	"id": 19,

	"type": "单选题",

	"title": "将自己养的宠物狗遗弃，这条狗流浪期间咬伤他人，是否需要承担责任？",

	"options": ["需要", "不需要"],

	"right": "A"

}, {

	"id": 20,

	"type": "单选题",

	"title": "住宅土地70年产权到期后，会发生什么？",

	"options": ["产权作废", "自动续期"],

	"right": "B"

}, {

	"id": 21,

	"type": "单选题",

	"title": "土地承包经营权可以转让吗？",

	"options": ["可以", "不可以"],

	"right": "A"

}, {

	"id": 22,

	"type": "单选题",

	"title": "小丽在一家公司上班，上司经常在网上聊天时发色情图片给她，这让她感到困扰、反感，上级这种行为算性骚扰吗？",

	"options": ["属于", "不属于"],

	"right": "A"

}, {

	"id": 23,

	"type": "单选题",

	"title": "人脸特征、指纹、航班行程是个人信息吗？",

	"options": ["是", "不是"],

	"right": "A"

}, {

	"id": 24,

	"type": "单选题",

	"title": "离婚时，此前为家庭付出更多的一方是否有权利请求补偿？",

	"options": ["有", "没有"],

	"right": "A"

}, {

	"id": 25,

	"type": "单选题",

	"title": "生前先后立了多份遗嘱，内容不同，应该以哪份遗嘱为准？",

	"options": ["以最后的遗嘱为准", "以第一份遗嘱为准"],

	"right": "A"

}, {

	"id": 26,

	"type": "单选题",

	"title": "夫妻双方气愤之下登记离婚，第二天和好又后悔离婚，多少天内可撤回离婚登记申请？",

	"options": ["30天", "40天"],

	"right": "A"

}, {

	"id": 27,

	"type": "单选题",

	"title": "未经允许，用AI换脸软件，将恶搞视频里主角的脸换成同事的脸，并在朋友圈进行传播，该行为侵犯了什么权利？",

	"options": ["肖像权", "名誉权"],

	"right": "A"

}, {

	"id": 28,

	"type": "单选题",

	"title": "《民法典》是新中国成立以来第一部以“法典”命名的法律，也是第一部直接以“民”命名的法典。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 29,

	"type": "单选题",

	"title": "《民法典》于2021年1月1日起正式施行。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 30,

	"type": "单选题",

	"title": "佛山居住证办理条件：公民离开其常住户口所在地，在佛山市居住地实际居住并办理居住登记满（   ），符合有合法稳定就业、合法稳定住所、连续就读条件之一的，凭居住登记回执，可申领佛山市居住证。",

	"options": ["3个月", "一年", "一个星期", "半年"],

	"right": "D"

}, {

	"id": 31,

	"type": "单选题",

	"title": "佛山居住证办理流程：申请人持所需材料到（   ）窗口办理。办理材料：①本人居民身份证或其他有效身份证明;②填写《佛山市居住证业务申请表》;③居住登记回执;④居住地址证明;⑤《广东省居住证数字相片采集检测回执》或使用二代居民身份证免照相办理。",

	"options": ["镇流管中心", "现居住地村居流管服务站", "当地派出所", "当地镇行政服务中心"],

	"right": "B"

}, {

	"id": 32,

	"type": "单选题",

	"title": "居住证持证人除享有第十条规定的公共服务外，在符合相关规定的情况下，还享有下列权益和公共服务：（一）在居住地申领机动车驾驶证，办理机动车注册登记手续； （二）在居住地办理出入港澳地区的商务签注手续； （三）享受孕前优生健康检查和“惠民平价产房”服务；（四）享受计划生育奖励优惠和便民服务；（五）随迁子女享受免费义务教育;（六）申请入户；（七）其他向本地市民提供的公共服务。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 33,

	"type": "单选题",

	"title": "按照顺德区新市民服务宣传月活动方案的通知要求2021 年宣传月活动的主题是（   ）。",

	"options": ["奋进新时代，筑梦新家园", "共享共治,家’顺德", "美丽顺德.共建共享,家’顺德", "新梦想,新家园"],

	"right": "A"

}, {

	"id": 34,

	"type": "单选题",

	"title": "流动人口申报居住登记、居住变更登记、首次领取居住证和办理居住证延期手续的，办理单位不得收取任何费用。居住证遗失、损坏的，持证人应当及时向居住地村（居）流 动人口服务管理工作机构或通过网络信息系统申请补领、换领， 并缴纳工本费。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 35,

	"type": "单选题",

	"title": "租赁房屋的出租屋主应当向房屋所在地村（居）流动人口服务管理工作机构报送下列信息;出租人、承租人的姓名;身份证件的种类和号码；联系方式；现住地址,出租屋主应当自签订房屋租赁合同之日起 ( )内报送； 未签订合同的，出租人应当自承租人入住之日起 （   ）内报送。",

	"options": ["一个月内", "5个工作日内", "3 个工作日内", "1个或2工作日内"],

	"right": "C"

}, {

	"id": 36,

	"type": "单选题",

	"title": "按照谁出租谁管理的原则,根据中华人民共和国治安处罚法第五十七条第一款第一项之规定,房屋出租人将房屋出租给无身份证件的人居住的，或者不按规定登记承租人姓名、身份证件种类和号码的,由公安机关处以处罚二百元以上五百元以下的罚款。",

	"options": ["200元", "100元", "50元", "150元"],

	"right": "A"

}, {

	"id": 37,

	"type": "单选题",

	"title": "出租屋房间内不得存放液化石油气瓶和使用燃气炉具等易燃易爆物品，严禁违规用火用电用气行为。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 38,

	"type": "单选题",

	"title": "设置禁火标志的部位不能使用明火,但不限制吸烟。",

	"options": ["正确", "错误"],

	"right": "B"

}, {

	"id": 39,

	"type": "单选题",

	"title": "在楼间等竖直方向上,烟火的运动速度要比人跑得快。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 40,

	"type": "单选题",

	"title": "到床底,阁楼找东西时,应用（   ）照明。",

	"options": ["手电筒", "油灯", "蜡烛"],

	"right": "A"

}, {

	"id": 41,

	"type": "单选题",

	"title": "持本市有效居住证的新市民，如有未曾入读公办小学或初中起始年级且符合入学条件的非本市户籍适龄随迁子女，可为其申请小学一年级或初中一年级公办学位新生排名，教育主管部门根据公布的入学指标数和排名情况，按照相对就近入学原则统筹安排新市民子女入读公办学校。简单来说，小孩年龄符合、父母有居住证、子女非本市户籍，皆可申请。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 42,

	"type": "单选题",

	"title": "申请人租住在我市出租屋（已在我市流动人口综合信息系统或佛山房屋租赁交易监管服务平台登记备案，不包含自住和借住亲友房屋的），每满1年计5分，最高可计（   ）分。",

	"options": ["10", "20", "30", "50"],

	"right": "C"

}, {

	"id": 43,

	"type": "单选题",

	"title": "申请人或其配偶办理了户籍地核发的国家统一格式的《流动人口婚育证明》（含全国统一的电子婚育证明）或《广东省计划生育服务证》（含电子证明），到达我市后按照有关办法在居住地卫生计生部门进行验证，并按照证件的管理年限及时换证，积（   ）分。",

	"options": ["2", "3", "4", "5"],

	"right": "D"

}, {

	"id": 44,

	"type": "单选题",

	"title": "符合居住登记条件的，领取《居住登记凭证》，登记满半年后可以申领佛山市居住证，居住证是作为本市常住人口登记并享受居住地政府基本公共服务和便利的依据。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 45,

	"type": "单选题",

	"title": "居所为以小时、天数为租期的出租房屋，居所报送义务人未按照规定报送租住人居住信息的，责令限期改正，逾期未改正的，处（   ）元罚款。",

	"options": ["100", "500", "1000", "200"],

	"right": "C"

}, {

	"id": 46,

	"type": "单选题",

	"title": "灭火器的使用步骤：1.（   ）展开出粉管；2.（   ）对准火焰边缘根部；3.（   ），打开灭火器阀门。",

	"options": ["用双手紧握喷枪,取下喷枪,拔掉保险销", "拔掉保险销,用双手紧握喷枪,取下喷枪", "取下喷枪,用双手紧握喷枪,拔掉保险销", "用双手紧握喷枪,取下喷枪,拔掉保险销"],

	"right": "C"

}, {

	"id": 47,

	"type": "单选题",

	"title": "当发生火灾的楼层在自己所处的楼层之上时，应迅速向楼下跑，因为火是向上蔓延的。千万不要盲目跳楼，可利用疏散楼梯、阳台、落水管等逃生自救。",

	"options": ["正确", "错误"],

	"right": "A"

}, {

	"id": 48,

	"type": "单选题",

	"title": "当烟雾呛人时，要用湿毛巾、浸湿的衣服等捂住头、耳并屏住呼吸，不要大声呼叫。要尽量使身体贴近地面，靠墙边爬行逃离火场。",

	"options": ["正确", "错误"],

	"right": "B"

}, {

	"id": 49,

	"type": "单选题",

	"title": "积分入学流程持佛山市有效（   ）的新市民，如有未曾入读公办小学或初中起始年级且符合入学条件的适龄随迁子女，可在其居住地为子女申请小学一年级或初中一年级公办学位新生排名。",

	"options": ["房产证", "毕业证", "身份证", "居住证"],

	"right": "D"

}, {

	"id": 50,

	"type": "单选题",

	"title": "张某通过电商平台购买了物品。下单成功后突然收到一条陌生短信，短信称其未购买成功，需要退款，要求张某提供退款的账号和密码。碰到这样的情况张某应该怎样做？",

	"options": ["接到这样的信息，应该按照对方的要求做", "相信并转账，等待退款", "退款不会要求提供账户密码，直接忽略", "陌生信息要求转账的，相信并提供"],

	"right": "C"

}, {

	"id": 51,

	"type": "单选题",

	"title": "想找兼职的小刘，在ＱＱ漂流瓶里面看到一则招聘网络兼职人员的信息，她了解到，这份在淘宝网上给商家“刷信誉”的工作非常轻闲，无需押金，更不用坐班，而且对方承诺，小刘每刷一笔商品，至少有15元钱的收入，每天至少可赚百元以上。小刘动了心，立刻垫钱刷了一笔500元的订单，结果可想而知。如果正想找工作的你，遇到了这样的招聘信息，你该怎么做？",

	"options": ["常见诈骗手段，主动忽略", "这种赚钱方式真轻松，赶快去应聘", "刚毕业就业困难，急用钱买新手机，日夜刷信誉赚钱", "发财致富之道，并介绍朋友一起发财"],

	"right": "A"

}, {

	"id": 52,

	"type": "单选题",

	"title": "如果有陌生人加您微信好友，您会怎么办？",

	"options": ["都加为好友", "所有的陌生人一律不加", "问清是谁再加为核证是否加好友", "自报姓名帮助对方回忆"],

	"right": "B"

}, {

	"id": 53,

	"type": "单选题",

	"title": "接到陌生电话，对方让您猜猜我是谁，您会怎么办？",

	"options": ["自我回忆猜测并相信对方是好人", "挂断", "相信", "自报姓名帮助对方回忆"],

	"right": "B"

}, {

	"id": 54,

	"type": "单选题",

	"title": "如果你接到一个自称在政府工作的人员，能说出你的名字且知道你是贫困生。对方表示要给你发放2000元的补贴，哪种处理方式最合理？",

	"options": ["根据对方要求提供自己的身份证号、银行账户等", "能说出姓名则不是诈骗，根据对方提示到ATM机上操作领取补贴", "挂断电话，打电话向相关政府部门进行核实", "向对方详细了解补贴情况"],

	"right": "C"

}, {

	"id": 55,

	"type": "多选题",

	"title": "小张在网上购买了一双球鞋，支付完毕后突然收到“淘宝客服”短信，称其支付没有成功，需要重新操作。这时小张该怎么做？",

	"options": ["进入自己的淘宝、支付宝账户查看订单状态", "按照短信提示进入相关链接进行操作", "和短信上提供的“淘宝客服”电话联系，询问详情", "自己联系卖家或淘宝网客服进行核实"],

	"right": "AD"

}, {

	"id": 56,

	"type": "多选题",

	"title": "小明在QQ上刚认识一网友，对方称可以帮其开通蚂蚁借呗并进行套现，预交手续费，此时小明的做法哪些是可取的？",

	"options": ["不相信网友，删掉好", "根据网友的要求缴费，开通蚂蚁借呗并套现", "怀疑网友，自己上支付宝终端查看蚂蚁借呗", "打电话给官方运营商核实是否可以这样操作"],

	"right": "ACD"

}, {

	"id": 57,

	"type": "多选题",

	"title": "警方根据多年打击防范电信诈骗的工作经验，总结提炼了“三个凡是”防诈骗口诀。“三个凡是”的内容是？",

	"options": ["凡是自称行政、司法部门来电要求转账、汇款的都是诈骗", "凡是未经认证的网站发布购物、购票信息的都是诈骗", "凡是通过电话、网络等方式要求转账、汇款至陌生人账户的都是诈骗", "凡是陌生人的来电都是诈骗"],

	"right": "ABC"

}, {

	"id": 58,

	"type": "多选题",

	"title": "使用二维码支付安全守则：（   ）。",

	"options": ["打开二维码付款界面等待出示时，记得遮挡手机屏幕", "改变刷脸验证代替输入支付密码的习惯", "用手机扫对方码支付时，要与收款方核对昵称", "网上交易时，识别对方二维码须认真阅读提示信息"],

	"right": "ABCD"

}];

//

//,{

//	"id": 59,

//	"type": "多选题",

//	"title": "冒充熟人校领导电话诈骗是冒充领导或者辅导员的骗术，是“猜猜我是谁”的升级版。骗子直呼你名，“明天到我办公室来一下！”受害人问：“是某老师吗？”骗子予以肯定答复。第二天，骗子再次来电，告知正在开会，找借口要求准备现金送给别人。稍后，骗子会要求改为向某账号转账汇款，多次行骗，直到受害人发现上当受骗。以下说法正确的是：（   ）。",

//	"options": ["听口音，这种诈骗类型具备比较强的地域特征，犯罪嫌疑人一般南方口音比较重，当接到这类口音电话时，要提高警惕", "问虚实，对方获取了事主部分信息，但是信息有限，可以反问对方是否是身边可以看到的人，如对方顺口回复，就可确认是骗子无疑", "如不能确定对方身份，可详述具体事件进行确认。如对方称是领导不方便直接问时，可向老师、同学侧面了解领导相关情况，谨慎核实", "一般是没有领导老师会向学生借钱应急的，涉及资金转账的，更应该先核实在观察"],

//	"right": "ABCD"

//}

export default qusedata;