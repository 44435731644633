<template>
    <div>
		<!--预加载-->
		<div id="page_loading">
			<div class="pl-box">
				<div class="sk-circle">
					<div class="sk-circle1 sk-child"></div>
					<div class="sk-circle2 sk-child"></div>
					<div class="sk-circle3 sk-child"></div>
					<div class="sk-circle4 sk-child"></div>
					<div class="sk-circle5 sk-child"></div>
					<div class="sk-circle6 sk-child"></div>
					<div class="sk-circle7 sk-child"></div>
					<div class="sk-circle8 sk-child"></div>
					<div class="sk-circle9 sk-child"></div>
					<div class="sk-circle10 sk-child"></div>
					<div class="sk-circle11 sk-child"></div>
					<div class="sk-circle12 sk-child"></div>
				</div>
				<p id="pl_num">{{loadingProcess}}%</p>
			</div>
		</div>

        <!-- 主体 -->
        <div id="main">
            <div id="index" class="pageBox backEnough">
                <div class="safeBox">
					<div class="logo animated fadeInDown">
						<img src="../assets/img/logo.png" alt="" srcset="" @click="uploadImg">
					</div>      
                    <div class="theme animated zoomIn">
                        <img src="../assets/img/theme.png" alt="" srcset="" @click="showImg">
                    </div>     
                    <div class="indexRule animated fadeInRight">
                        <img src="../assets/img/indexRule.png" alt="" srcset="" @click="showMap">
                    </div>   
                    <div class="myGift animated fadeInRight">
                        <img src="../assets/img/myGift.png" alt="" srcset="" @click="showGift">    
                    </div>
                    <div class="begin animated fadeInUp">
                        <img src="../assets/img/begin.png" alt="" srcset="">
                    </div>      
                </div>
            </div>
            <div id="question" class="pageBox backEnough">
                <div class="safeBox">
                    <div class="return animated fadeInLeft">
                        <img src="../assets/img/return.png" alt="" srcset="" @click="angin">
                    </div>
                    <div class="question animated zoomIn">
                        <div class="qTitleBox rela">
                            <img src="../assets/img/qTitle.png" alt="" srcset="">
                            <div class="tihao">
                                <span>{{wx.num}} </span> / {{wx.max}}
                            </div>
							<div class="qTitle">
								<!-- <p>居住证持证人除享有第十条规定的公共服务外，在符合相关规定的情况下，还享有下列权益和公共服务：（一）在居住地申领机动车驾驶证，办理机动车注册登记手续； （二）在居住地办理出入港澳地区的商务签注手续； （三）享受孕前优生健康检查和“惠民平价产房”服务；（四）享受计划生育奖励优惠和便民服务；（五）随迁子女享受免费义务教育;（六）申请入户；（七）其他向本地市民提供的公共服务。（单选题）</p> -->
                                <p>{{wx.curData.title}} （{{wx.curData.type}}）</p>
                            </div>                            
                        </div>
                        <div class="answerBox">
                            <!-- <div class="answer right">
                                <span class="centerCenter">A</span>
                                <p>所有已婚育龄妇女进行计划生育手术均可报销</p>
                            </div>
                            <div class="answer has error">
                                <span class="centerCenter">B</span>
                                <p>所有已婚育龄妇女进行计划生育手术均可报销</p>
                            </div>                             -->
                            <div v-for="(item,index) in wx.curData.options" :key="index">
                                <div v-if="wx.curData.type=='单选题'" :class="wx.curAnIndex==index?'answer has':'answer'" @click="singleClick(index)">
                                    <span class="centerCenter">{{wx.ra[index]}}</span>
                                    <p>{{item}}</p>
                                </div>
                                <div v-if="wx.curData.type=='多选题'" :class="wx.curAnIndexs.includes(index)?'answer has':'answer'" @click="AnsClick(index)">
                                    <span class="centerCenter">{{wx.ra[index]}}</span>
                                    <p>{{item}}</p>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="confirmQues">
                        <img src="../assets/img/confrim.png" alt="" srcset="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 游戏规则 -->
        <div id="rule" class="mask7" style="display: none;">
            <div class="safeBox centerCenter">
                <div class="ruleBox animated zoomIn rela">
                    <img src="../assets/img/ruleBox.png" alt="" srcset="">
                    <div class="rule" id="rScroller">
                        <ScrollView>
                            <ul>
                                <li class="rela">
                                    <img src="../assets/img/ruleTxt.png" alt="" srcset="">
                                </li>
                            </ul>
                        </ScrollView>
                    </div>
                    <div class="more horCenter noneTouch">
                        <img src="../assets/img/more.png" class="downMore" alt="" srcset="">
                    </div>
                </div>
                <div class="confirmRule animated fadeInUp mt20">
                    <img src="../assets/img/confrim.png" alt="" srcset="">
                </div>                
            </div>
        </div>

        <!-- 答题完毕之全对 -->
        <div id="answerRight" class="mask7" style="display: none;">
            <div class="safeBox centerCenter">
                <div class="answerRight animated zoomIn">
                    <img src="../assets/img/answerRight.png" alt="" srcset="">
                </div>
                <div class="twoBtn divFlexBetween mt30">
                    <div class="award animated fadeInUp">
                        <img src="../assets/img/award.png" alt="" srcset="" @click="award">
                    </div>
                    <div class="confirmAward animated fadeInUp" style="display: none;">
                        <img src="../assets/img/confrim.png" alt="" srcset="">
                    </div>
                    <div class="shareBtn animated fadeInUp">
                        <img src="../assets/img/shareBtn.png" alt="" srcset="" @click="share">
                    </div>
                </div>
            </div>
        </div>

        <!-- 答题完毕之没全对 -->
        <div id="answerError" class="mask7" style="display: none;">
            <div class="safeBox centerCenter">
                <div class="answerError animated zoomIn">
                    <img src="../assets/img/answerError.png" alt="" srcset="">
                </div>
                <div class="twoBtn divFlexBetween mt30">
                    <div class="again animated fadeInUp">
                        <img src="../assets/img/again.png" alt="" srcset="" @click="angin">
                    </div>
                    <div class="shareBtn animated fadeInUp">
                        <img src="../assets/img/shareBtn.png" alt="" srcset="" @click="share">
                    </div>
                </div>
            </div>
        </div>

        <!-- 抽奖中 -->
        <div id="prizeLoading" class="mask7" style="display: none;">
            <div class="safeBox centerCenter rtt90">
                <img src="../assets/img/prizeLoading.gif" alt="" srcset="">
            </div>
        </div>

        <!-- 未中奖 -->
		<div id="pity" class="mask7" style="display: none;">
			<div class="safeBox centerCenter">
				<div class="pity animated zoomIn">
					<img src="../assets/img/pity.png" alt="" srcset="">
				</div>
				<div class="twoBtn divFlexBetween mt30">
					<div class="again animated fadeInUp">
						<img src="../assets/img/again.png" alt="" srcset="">
					</div>
					<div class="shareBtn animated fadeInUp">
						<img src="../assets/img/shareBtn.png" alt="" srcset=""  @click="share">
					</div>
				</div>
			</div>
		</div>  

        <!-- 中奖 -->
        <div id="gift" class="mask7" style="display: none;">
            <div class="safeBox centerCenter">
                <div class="gift animated zoomIn">
                    <img src="../assets/img/gift1.png" alt="" srcset="">
                </div>
                <div class="twoBtn divFlexBetween mt30">
                    <div class="comfrimGifts animated fadeInUp">
                        <img src="../assets/img/confrim.png" alt="" srcset="">
                    </div>
                    <div class="shareBtn animated fadeInUp">
                        <img src="../assets/img/shareBtn.png" alt="" srcset="" @click="toSave">
                    </div>
                </div>                  
            </div>          
        </div>    

        <!-- 我的奖品 -->
        <div id="myGiftBox" class="mask7" style="display: none;">
            <div class="safeBox centerCenter">
                <div class="myGift1 animated zoomIn">
                    <img src="../assets/img/myGift1.png" alt="" srcset="">
                </div>
                <div class="twoBtn divFlexBetween mt30">
                    <div class="comfrimGift animated fadeInUp">
                        <img src="../assets/img/close.png" alt="" srcset="" @click="closeShowGift">
                    </div>
                    <div class="shareBtn animated fadeInUp">
                        <img src="../assets/img/shareBtn.png" alt="" srcset="" @click="toSave">
                    </div>                         
                </div>     
            </div>          
        </div>        

        <!-- 领奖地址 -->
		<div id="map" class="mask7" style="display: none;">
			<div class="safeBox centerCenter">
				<div class="map animated zoomIn rela">
					<!-- <img src="../assets/img/map.png" alt="" srcset="../assets/img/prizeLoading.gif 1000w"> -->
                    <img src="../assets/img/map.png" alt="" srcset="" @click="toAdrress">
				</div>
				<div class="confrimMap animated fadeInUp mt20">
					<img src="../assets/img/confrim.png" alt="" srcset="" @click="closeMap">
				</div>
			</div>
		</div>        

		<!--分享-->
		<div id="shares" class="mask7" style="display: none;" @click="closeShares">
			<img src="../assets/img/shareTxt.png" alt="" srcset="">
		</div>              

        <!-- 生成海报 -->
        <div id="showPost" class="mask7 centerCenter" style="display: none;">
            <div class="showPost">
                <img src="" id="img" alt="" srcset="" />
            </div>
            <div class="showPostTips"><p>长按图片保存，分享朋友圈</p></div>
            <div class="closePostShow" @click="hidePost"></div>
        </div>

        <!-- 背景音乐 -->
        <div id="music"></div>
        <audio autoplay="autoplay" controls hidden="hidden" id="bg" loop="loop" preload>
            <source src="../assets/bg.mp3" />
        </audio>
        <canvas width="640" height="1138" id="canvas" ></canvas>
    </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getSign } from '../api/index'
import { reactive, ref } from 'vue'
import { useRoute,useRouter } from 'vue-router'
require('yuki-createjs/lib/preloadjs-0.6.2.combined')
require('yuki-createjs/lib/soundjs-0.6.2.combined')
import ScrollView from '../components/ScrollView.vue'
import quseData from '../assets/js/data'
import tool from '../assets/js/canvas'

// import { Toast } from 'mint-ui'

export default {
    name:'Index',
    components: {
        ScrollView
    },
    setup(){
        const obj = {curData:{type:''},ra:["A", "B", "C", "D", "E", "F"],curAnIndex:-1,curAnIndexs:[]};
        const wx = reactive(obj)

        const createMusic = ()=> {
            let vo = document.getElementById("bg");               
            let music = 0;
            let musicOpen = true;
            let musicTween = setInterval(function(){
                music += 2;
                // $('#music').css('transform',"rotate(" + music + "deg)");
                document.getElementById('music').style.transform = `rotate(${music}deg)`;
                if(music==360){
                    music = 0;
                }
            },10);
            document.getElementById('music').addEventListener('click',(e)=>{
                if(musicOpen){
                    musicOpen = false;
                    clearInterval(musicTween);
                    vo.pause();
                } else {
                    musicOpen = true;
                    musicTween = setInterval(function(){
                        music += 2;
                        document.getElementById('music').style.transform = `rotate(${music}deg)`;
                        if(music==360){
                            music = 0;
                        }
                    },10);  
                    vo.play();                  
                }
            });
            audioAutoPlay('bg');
        }  
        const audioAutoPlay = (id, v)=> {
            var audio = document.getElementById(id);
            audio.play();
            if(v == 0) {
                audio.pause()
            }
            document.addEventListener("WeixinJSBridgeReady", function() {
                audio.play();
                if(v == 0) {
                    audio.pause()
                }
            }, false)
        }        
        
        // 已加载的百分比
        const loadingProcess = ref(0);    
        // preloadjs预加载资源
        const loadResource = ()=> {
            var resource = new createjs.LoadQueue(false);
            var mainfest = [
                { id:'logo',src:'../assets/img/logo.png' },
                { id:'hll_logo',src:'../assets/img/hll_logo.jpg' },
                { id:'new',src:'../assets/img/new.jpg' },
                { id:'new2',src:'../assets/img/new2.jpg' },
                { id:'video',src:require('../assets/screen.mp4'),data:5},
                { id:'bgsound',src:require('../assets/bg.mp3'),data:6},
                { id:'right',src:require('../assets/media/right.mp3'),data:7 },
                { id:'error',src:require('../assets/media/error.mp3'),data:8 }
            ];
	        createjs.Sound.alternateExtensions = ["mp3"];
	        resource.installPlugin(createjs.Sound);
	        createjs.Sound.registerPlugins([createjs.WebAudioPlugin, createjs.FlashAudioPlugin]);
            // createjs.Sound.registerSound(require('../assets/media/right.mp3'),'right');  单音频注册
            // createjs.Sound.registerSounds(mainfest);  多音频注册
            resource.on('progress',function(e){
                // console.log(e);
                loadingProcess.value = Math.round(e.progress * 100);
            },this)
            resource.on('complete',()=>{
                // document.getElementById('page_loading').className += ' moveOut';
                document.getElementById('page_loading').style.display = 'none';
                wxMain();
            },this)
            resource.loadManifest(mainfest);
            resource.load();
        }   

        const wxMain = ()=> {
            // wxpage().init('index');
            wx.page = wxpage();
            wx.page.init('index');
            wx.can = false;
            wx.rScroller = false;
            setTimeout(function(){
                if(!wx.rScroller){
                    document.getElementById('rule').style.display = 'block';
                }
            },800);
            document.getElementsByClassName('confirmRule')[0].addEventListener('click',function(){
                document.getElementById('rule').style.display = 'none';
                // $(this).addClass('active').siblings().removeClass('active');
            });
            document.getElementsByClassName('begin')[0].addEventListener('click',function(){
                reset();
                wx.page.to('question');
            });
            document.getElementsByClassName('confirmQues')[0].addEventListener('click',function(){
                // createjs.Sound.play('right');
                var answer = "";
                for(var i=0;i<document.getElementsByClassName('answer').length;i++){
                    if(document.getElementsByClassName('answer')[i].classList.contains('has')){
                        answer += wx.ra[i];
                        var yes = false;
                        for(var k=0;k<wx.curData.right.length;k++){
                            if(wx.curData.right[k] === wx.ra[i]){
                                yes = true;
                            }
                        }
                        if(yes){
                            document.getElementsByClassName('answer')[i].classList.add('right');
                        }else{
                            document.getElementsByClassName('answer')[i].classList.add('error');
                        }                        
                    }
                }
                if(answer==''){
                    // alert('请选择答案');
                    // this.$message('这是一条消息提示');
                    
                }else if(wx.can){
                    wx.can = false;
                    var right = '';
                    for(var k=0;k<wx.curData.right.length;k++){
                        // console.log(wx.ra.indexOf(wx.curData.right[k]));
                        document.getElementsByClassName('answer')[wx.ra.indexOf(wx.curData.right[k])].classList.add('right');
                        right += wx.curData.right[k].toLocaleUpperCase();
                    }
                    if(right==answer){
                        wx.score++;
                        createjs.Sound.play('right');
                    }else{
                        createjs.Sound.play('error');
                    }
                    setTimeout(function(){
                        if(wx.num == wx.max){
                            console.log(wx.score);
                            Over();
                        }else{
                            wx.num+=1;
                            setQuestion();
                        }
                    },500);
                }
            });
        }
        
        const wxpage = ()=> {
            var _r = {};
            var $pNow = null,
                $pLast = null,
                z = 2;
            _r.init = function(toPage){
                $pLast = $pNow;
                $pNow = toPage;
                const curPage = document.getElementById($pNow);
                curPage.style.zIndex = z;
                curPage.style.display = 'block';
                setTimeout(function() {
                    curPage.classList.add('page-on');
                }, 50)
            };
            _r.to = function(toPage){
                $pLast = $pNow;
                $pNow = toPage;   
                if($pNow==$pLast){
                    return 
                } 
                z += 1;
                const curPage = document.getElementById($pNow);
                curPage.style.zIndex = z;
                curPage.style.display = 'block';                            
            }
            // document.getElementsByClassName('pageBox').addEventListener('webkitAnimationEnd',function(){
            //     document.getElementsByClassName('pageBox').classList.add('page-on');
            //     document.getElementById($pLast).classList.remove('page-on');
            // });
            return _r;
        }

        const reset = ()=> {
            wx.num = 1;
            wx.max = 8;
            wx.score = 0;
            wx.data = random(quseData,wx.max);
            setQuestion();
        }

        const random = (array,length)=>{
            var type = [];
            array.forEach((item,index)=>{
                type.push(index);
            })
            var arr = [];
            for(var i=0;i<length;i++){
                var random = parseInt(Math.random()*type.length);
                arr.push(array[type[random]]);
                type.splice(random,1);
            }
            return arr;
        }

        const setQuestion = ()=>{
            wx.curData = wx.data[wx.num-1];
            wx.curAnIndex = -1;
            wx.curAnIndexs = [];
            wx.can = false;
            document.getElementsByClassName('confirmQues')[0].style.display = 'none';
            for(var i=0;i<document.getElementsByClassName('answer').length;i++){
                document.getElementsByClassName('answer')[i].classList.remove('right');
                document.getElementsByClassName('answer')[i].classList.remove('error');
            }            
            setTimeout(function(){
                wx.can = true;
                document.getElementsByClassName('confirmQues')[0].style.display = 'block';
            },200);
        }

        // 单选
        const singleClick = (index)=>{
            wx.curAnIndex = index;
        }

        // 多选
        const AnsClick = (index)=>{
            if(wx.curAnIndexs.includes(index)){
                wx.curAnIndexs = wx.curAnIndexs.filter(item=>item!=index);
            }else{
                wx.curAnIndexs.push(index);
            }
        }

        // 答题完毕
        const Over = ()=>{
            wx.can = true;
            if(wx.score>=8){
                document.getElementById('answerRight').style.display = 'block';
            }else{
                document.getElementById('answerError').style.display = 'block';
            }
        }

        // 重新挑战
        const angin = ()=>{
            location.reload();
        }

        // 抽奖
        const award = ()=>{
            document.getElementById('answerRight').style.display = 'none';
            document.getElementById('prizeLoading').style.display = 'block';
            setTimeout(function(){
                document.getElementById('prizeLoading').style.display = 'none';
                document.getElementById('gift').style.display = 'block';
            },1000);
        }

        // 分享
        const share = ()=>{
            document.getElementById('shares').style.display = 'block';
        }
        // 关闭分享
        const closeShares = ()=>{
            document.getElementById('shares').style.display = 'none';
        }

        // 我的奖品
        const showGift = ()=>{
            document.getElementById('myGiftBox').style.display = 'block';
        }
        // 关闭我的奖品
        const closeShowGift = ()=>{
            document.getElementById('myGiftBox').style.display = 'none';
        } 

        // 领奖地址
        const showMap = ()=>{
            document.getElementById('map').style.display = 'block';
        }
        const closeMap = ()=>{
            document.getElementById('map').style.display = 'none';
        }

        // 生成海报
        const toSave = ()=>{
            var img = [require('../assets/img/bottom.png'),require('../assets/img/myGift1.png'),require('../assets/img/post.png'),require('../assets/img/er.png')];

            var ix = [30,34 ,0,469];

            var iy = [279,283,0,939];

            var iw = [580,572,640,141];

            var ih = [580,572,1138,141];

            var txt = [
            "主题：",
            "标题：",
        //	"姓名："+returnTxt($('#detail_7').text()),
            "地点："];

            var tx = [40,40,40,40];

            var ty = [1046,1081,1116];
        
            var tfont = ["21px arial","21px arial","21px arial"];

            var tColor = ['#ffffff','#ffffff','#ffffff'];

            tool.createToImage("canvas", 640, 1138, img, ix, iy, iw, ih, 'img', true, txt, tx, ty, tfont, tColor,function(){

                // $('#showPost').moveIn();
                console.log('渲染成功!');
                document.getElementById('showPost').style.display = 'block';
                document.getElementById('showPost').style.display = 'flex';

            });

        }

        // 关闭海报图层
        const hidePost = ()=>{
            document.getElementById('showPost').style.display = 'none';
        }

        return{
            wx,
            loadingProcess,
            createMusic,
            loadResource,
            singleClick,
            AnsClick,
            angin,
            award,
            share,
            closeShares,
            showGift,
            closeShowGift,
            showMap,
            closeMap,
            toSave,
            hidePost
        }          
    },
    mounted(){
        this.createMusic();
        this.loadResource();   
        // const txt = tool.save();
        // console.log(txt);

        const route = new useRoute();
        const openid = localStorage.getItem('openid');
        if(!route.query.weixin || openid==null){
            const appId = 'wxa0cd9fceb218fea0';
            // const redirect_uri = encodeURIComponent('http://192.168.1.100:8080/getUserInfo');
            // const redirect_uri = encodeURIComponent('http://dttest.com/getUserInfo');
            const redirect_uri = encodeURIComponent('http://8.134.109.162/getUserInfo');
            const url =`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
            // const url = 'https://hpay.hstypay.com/app/payorder-dc2afd7598be4984859a6254485a6013';
            window.location.href = url              
        }
        this.getOpen();
    },
    methods:{
        getOpen(){
            let params = {shareUrl: location.href.split('#')[0]}
            getSign(params).then((res)=>{
                console.log(res);
                // alert(location.href.split('#')[0])            
                wx.config({
                    debug: false, // 开启调试模式,
                    appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
                    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.signature,// 必填，签名，见附录1
                    jsApiList: ['onMenuShareAppMessage','openLocation','chooseImage','previewImage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2              
                });            
                wx.ready(function(){
                    // 在这里调用微信开发接口
                    wx.onMenuShareAppMessage({
                        title: '金榜题名', // 分享标题
                        desc: '祝各位考生旗开得胜，过五关斩六将，拿下最后的胜利!', // 分享描述
                        link: 'http://192.168.1.161:8080', // 分享链接
                        imgUrl: 'https://h5.kyhdmedia.com/ky/2104lcdt/img/icon.jpg', // 分享图标
                        type: '', // 分享类型,music、video或link，不填默认为link
                        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                        trigger: function (res) {
                        },
                        success: function (res) {
                        },
                        cancel: function (res) {
                        },
                        fail: function (res) {
                        }
                    });
                })
            })            
        },

        // 导航
        toAdrress(){
            wx.openLocation({
                latitude: 22.919321, // 纬度，浮点数，范围为90 ~ -90
                longitude: 113.075296, // 经度，浮点数，范围为180 ~ -180。
                name: '佛山市顺德区乐从镇水藤村水藤行政服务站', // 位置名
                address: '佛山市顺德区乐从镇水藤村新街涌边路与深氹大街巷交叉口南200米', // 地址详情说明
                scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
                // infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
            })
        },

        // 选择图片
        uploadImg(){
            wx.chooseImage({
                count:1,
                sizeType:['original','compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                success: function (res) {
                    // var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    console.log(res.localIds);
                }                
            })
        },

        // 预览图片
        showImg(){
            wx.previewImage({
                current: 'https://h5.kyhdmedia.com/ky/2104lcdt/img/icon.jpg', // 当前显示图片的http链接
                urls: ['https://h5.kyhdmedia.com/ky/2104lcdt/img/icon.jpg'] // 需要预览的图片http链接列表                
            })
        }
    }
}
</script>