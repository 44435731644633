const tool = {
    save(){
        const arr = this.getArrs();
        return arr;
    },
    getArrs(){
        const stus = ['张三','李四','王五'];
        return stus;
    },

    createToImage(canvasId, canvasWidth, canvasHeight, imgSrc, imgX, imgY, imgW, imgH, imgContainer, textStatus, txt, txtX, txtY, font, color,callBack){
        // var canvasHtml = '<canvas width="' + canvasWidth + '" height="' + canvasHeight + '" id="' + canvasId + '" ></canvas>';
        // document.body.append(canvasHtml);
        var that = this;
        var canvasObject = this.getCanvasContext(canvasId);
        canvasObject.clearRect(0, 0, canvasWidth, canvasHeight);
        var imgI = 0;
        var imgL = imgSrc.length;
        var txtI = 0;
        var txtL = txt.length; 
        that.createImgTocanvas(canvasObject, imgSrc, imgX, imgY, imgW, imgH, imgI, imgL, function() {
            if(textStatus == false) {
                var mycanvas = document.getElementById(canvasId);
                var image = mycanvas.toDataURL("image/png");
                document.getElementById(imgContainer).src = image;
                setTimeout(function() {
                    document.getElementById(canvasId).style.display = 'none';
                    if(typeof callBack == 'function') {
                        callBack();
                    }
                }, 500)                
            }else{
                that.createTextTocanvas(canvasObject, txt, txtX, txtY, font, color, txtI, txtL, function() {
                    var mycanvas = document.getElementById(canvasId);
                    var image = mycanvas.toDataURL("image/png");
                    document.getElementById(imgContainer).src = image;
                    setTimeout(function() {
                        document.getElementById(canvasId).style.display = 'none';
                        if(typeof callBack == 'function') {
                            callBack();
                        }
                    }, 500) 
                })
            }
        })       
    },

    getCanvasContext(id){
        return document.getElementById(id).getContext('2d')
    },

    createImgTocanvas(canvasObject, src, x, y, width, height, startI, length, callBack) {
        var that = this;
        var starImg = new Image();
        starImg.src = src[startI];
        starImg.onload = function(){
            if(startI == 1)
            {  
                if(starImg.width>=starImg.height)
                {
                    var height1 = width[startI]*starImg.height/starImg.width;
                    y[startI] += (height[startI]-height1)/2;
                    height[startI] = height1;
                }else{
                    var width1 = height[startI]*starImg.width/starImg.height;
                    x[startI] += (width[startI]-width1)/2;
                    width[startI] = width1;
                }
                console.log(x[startI], y[startI], width[startI], height[startI]);
            }
            canvasObject.drawImage(starImg, x[startI], y[startI], width[startI], height[startI]);
            startI += 1;
            if(startI != length) {
                that.createImgTocanvas(canvasObject, src, x, y, width, height, startI, length, callBack)
            } else {
                if(typeof callBack == 'function') {
                    callBack()
                }
            }
        }
    },

    createTextTocanvas(canvasObject, txt, x, y, font, color, startI, length, callBack) {
        canvasObject.font = font[startI];
        canvasObject.fillStyle = color[startI];
        canvasObject.fillText(txt[startI],x[startI],y[startI]);
        startI += 1;
        if(startI!=length){
            this.createTextTocanvas(canvasObject, txt, x, y, font, color, startI, length, callBack)
        }else{
            if(typeof callBack == 'function'){
                callBack()
            }
        }
    },
};

export default tool